import { type ComponentType } from 'react';
import {
  type MutationFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

import {
  http,
  invalidateGetRoomsQueryData,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';

/**
 * POST - /api/v1/eames/customer_order_line_items/bulk_move
 */

// HTTP

export interface BulkMoveCustomerOrderLineItemsXhrParams {
  fromProjectId: number;
  toProjectId: number;
  itemsToMove: {
    id: number;
    qty: number;
    roomId: number;
    toRoomId?: number;
  }[];
}

export interface BulkMoveCustomerOrderLineItemsXhrResponse {
  errors: Record<string, string[]>[];
}

export interface BulkMoveCustomerOrderLineItemsXhrVariables {
  params: BulkMoveCustomerOrderLineItemsXhrParams;
}

export const bulkMoveCustomerOrderLineItemsXhr = (
  params: BulkMoveCustomerOrderLineItemsXhrParams
): Promise<BulkMoveCustomerOrderLineItemsXhrResponse> =>
  http
    .post<BulkMoveCustomerOrderLineItemsXhrResponse>(
      `/api/v1/eames/customer_order_line_items/bulk_move`,
      params
    )
    .then((res) => res.data);

// MutationFn

type BulkMoveCustomerOrderLineItemsMutation = MutationFunction<
  BulkMoveCustomerOrderLineItemsXhrResponse,
  BulkMoveCustomerOrderLineItemsXhrVariables
>;

export const bulkMoveCustomerOrderLineItemsMutationFn: BulkMoveCustomerOrderLineItemsMutation =
  ({ params }) => bulkMoveCustomerOrderLineItemsXhr(params);

// Hook
export const useBulkMoveCustomerOrderLineItemsMutation = () => {
  const client = useQueryClient();

  return useMutation(bulkMoveCustomerOrderLineItemsMutationFn);
};

// With Mutation HOC

const withBulkMoveCustomerOrderLineItemsMutationPropKey =
  'bulkMoveCustomerOrderLineItemsMutation';

export type WithBulkMoveCustomerOrderLineItemsMutationProps =
  WithMutationPropsByMutation<
    typeof withBulkMoveCustomerOrderLineItemsMutationPropKey,
    BulkMoveCustomerOrderLineItemsMutation
  >;

export function withBulkMoveCustomerOrderLineItemsMutation<
  P extends WithBulkMoveCustomerOrderLineItemsMutationProps
>(
  Component: ComponentType<P>
): ComponentType<
  Omit<P, keyof WithBulkMoveCustomerOrderLineItemsMutationProps>
> {
  return withMutation(
    withBulkMoveCustomerOrderLineItemsMutationPropKey,
    bulkMoveCustomerOrderLineItemsMutationFn,
    (client) => ({
      onSuccess: (data, { params }) => {
        if (data.errors?.length > 0) return;
        invalidateGetRoomsQueryData(client, [params.fromProjectId]);
      },
    })
  )(
    Component as ComponentType<WithBulkMoveCustomerOrderLineItemsMutationProps>
  );
}
